// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-fonts-abril-fatface-js": () => import("./../../../src/pages/fonts/abril-fatface.js" /* webpackChunkName: "component---src-pages-fonts-abril-fatface-js" */),
  "component---src-pages-fonts-bungee-js": () => import("./../../../src/pages/fonts/bungee.js" /* webpackChunkName: "component---src-pages-fonts-bungee-js" */),
  "component---src-pages-fonts-ibm-plex-serif-js": () => import("./../../../src/pages/fonts/ibm-plex-serif.js" /* webpackChunkName: "component---src-pages-fonts-ibm-plex-serif-js" */),
  "component---src-pages-fonts-js": () => import("./../../../src/pages/fonts.js" /* webpackChunkName: "component---src-pages-fonts-js" */),
  "component---src-pages-fonts-merriweather-js": () => import("./../../../src/pages/fonts/merriweather.js" /* webpackChunkName: "component---src-pages-fonts-merriweather-js" */),
  "component---src-pages-fonts-open-sans-js": () => import("./../../../src/pages/fonts/open-sans.js" /* webpackChunkName: "component---src-pages-fonts-open-sans-js" */),
  "component---src-pages-fonts-ultra-js": () => import("./../../../src/pages/fonts/ultra.js" /* webpackChunkName: "component---src-pages-fonts-ultra-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

