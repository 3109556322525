module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Type Neighbor","short_name":"Type Neighbor","start_url":"/","background_color":"#000000","theme_color":"#ffff00","display":"standalone","icon":"src/assets/images/icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"6992f8a0d6d2a970622afd4cf1543f92"},
    },{
      plugin: require('../node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"custom":{"families":["Merriweather:n3,i3,n4,i4,n7,i7,n9,i9","Open Sans:n3,i3,n4,i4,n6,i6","IBM Plex Serif:n1,i1,n2,i2,n3,i3,n4,i4,n5,i5,n6,i6,n7,i7","Bungee:n4","Bungee Outline:n4","Bungee Inline:n4","Bungee Shade:n4","Bungee Hairline:n4","Ultra:n4","Abril Fatface:n4","Recursive:n3,n4,n5,n6,n7,n8,n9"]}},
    },{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
